@import "../../style/variables.scss";

.interaction-card {
  border: none;
  border-radius: 0;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  width: 300px;

  > .card-body {
    background-color: white;
    height: 300px;
    width: 300px;

    .interaction-card-details {
      z-index: 1;
      text-align: left;
      h3 {
        color: white;
        z-index: 1;
      }
      p {
        color: $white-2;
      }
    }

    .interaction-card-image {
      position: absolute;
      top: 0;
      left: 0;
      height: 300px;
      width: 300px;
      object-fit: cover;
    }

    .interaction-card-overlay {
      position: absolute;
      backdrop-filter: blur(3px);
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
      height: 100%;
      left: 0;
      top: 0;
      width: 100%;
    }
  }

  h3 {
    font-weight: bold;
  }

  p {
    font-size: 0.75rem;
  }
}

// .interaction-card-details {
//   position: absolute;
//   bottom: 10px;
//   left: 15px;
//   color: white;
// }

.interaction-card {
  transition: all 0.1s ease-in-out;
}
.interaction-card:hover {
  transform: scale(1.05);
  cursor: pointer;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
}
