@import "../../style/variables.scss";

// @media (max-width: 991px) {
//   .navbar:not(.dark-background) {
//     .navbar-collapse {
//       background: rgba(0, 0, 0, 0.3);
//     }
//   }
// }

.contact-wrapper {
  margin-left: auto !important;
  padding: 5px;
  justify-content: flex-end;
}

.phone-wrapper {
  display: table;
  height: 100%;
  svg {
    padding-bottom: 2px;
  }

  a.phone {
    display: table-cell;
    vertical-align: middle;
    color: $white;
    text-align: center;
  }
}

.row > .navbar-toggler {
  margin-left: auto;
  margin-right: 5px;
}

.navbar-collapse {
  margin-right: 5px;
}

.navbar-brand {
  img {
    height: auto;

    @media (max-width: 480px) and (orientation: portrait),
      (max-height: 480px) and (orientation: landscape) {
      width: 120px;
    }

    @media (min-width: 481px) and (orientation: portrait),
      (min-height: 481px) and (orientation: landscape) {
      width: 185px;
    }

    @media (min-width: 768px) and (orientation: portrait),
      (min-height: 768px) and (orientation: landscape) {
      width: 250px;
    }
  }
}

.fixed-top {
  transition: background 0.5s ease;
}

.fixed-top.dark-background,
.fixed-top.open-background {
  background: rgba(0, 0, 0, 0.7);
}

.navbar-expand,
.navbar-dark {
  .nav-link:hover,
  .nav-link:focus {
    color: $orange;
  }

  .active > .nav-link {
    color: $orange;
  }

  .navbar-nav {
    align-items: flex-end;
    .nav-link {
      color: $white;
      font-size: 1rem;
      padding-left: 3em;

      &.active {
        color: $orange;
      }
    }
  }
}
