@import "../../style/variables.scss";

.MuiPaper-root,
.modal-content {
  background-color: $white-2 !important;
}

.step-icon {
  height: 30px;
  width: 30px;
}

.MuiTypography-body2 {
  font-family: Montserrat !important;
}
