@import "./style/variables.scss";

/* make the customizations */
$theme-colors: (
  "primary": $gold-1,
  "danger": $red-1,
);

@import "~bootstrap/scss/bootstrap";
// @import "./style/fonts.scss";

body {
  font-family: Montserrat;
  background-color: $white-2;
}

h1,
h2,
h3,
h4 {
  font-family: Lato;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.8;
}

body .navbar-nav {
  margin-right: 0px;
  margin-left: 0px;
}

p.description-info {
  line-height: 1.75rem;
  color: hsl(200deg, 11%, 15%);
}

p.sub-header {
  font-size: 12px;
  line-height: 2.33em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-weight: 500;
  color: #565969;
  display: inline-block;
  margin: 0 0 5px;
}

.container-fluid {
  padding-left: 0;
  padding-right: 0;
  margin-right: 0;
  margin-left: 0;
}

.route-container {
  margin-top: 150px;
  min-height: 75vh;
}

.page-container {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.btn-group {
  .btn-outline-primary:not(:disabled):not(.disabled).active {
    background-color: rgba(0, 0, 0, 0);
    border-radius: 0;
    border: 0px;
    border-bottom: 2px solid;
    color: $blue;
    font-weight: bold;
  }

  .btn-outline-primary:not(:disabled):not(.disabled).active:hover {
    background-color: rgba(0, 0, 0, 0);
  }

  .btn-outline-primary:not(.active) {
    background-color: rgba(0, 0, 0, 0);
    border-radius: 0;
    border: 0px;
    color: $blue;
  }

  .btn-outline-primary:not(.active):hover {
    border-bottom: 2px solid;
    font-weight: bold;
    background-color: rgba(0, 0, 0, 0);
  }
}
