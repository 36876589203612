$white: #fff;
$white-1: #f2f2f2;
$white-2: rgb(247, 249, 250);

$gray: #c5c5c1;
$gray-1: #708090;
$gray-2: #f2f2f2;
$gray-3: #0000008c;
$gray-4: #565969;

$blue: #3952a6;
$blue-2: #00a3d7;
$blue-3: #2e4b74;
$blue-4: #7388d8;
$blue-5: #c3cdf3;
$blue-6: #2d4286;
$blue-7: #505e94;

$red-1: #ad262c;
$red-2: #ca2f36;

$orange: #eae083;
// $orange: #fd903b;
$gold: #eae083;
$gold-1: #a3994a;
$gold-2: #ecead3;
$gold-3: #65591f;
