@import "../../style/variables.scss";

.fluid-footer {
  background-color: $gray-3;
  color: $white-1;
  img.logo {
    width: 200px;
    height: auto;
  }

  a.address,
  a.phone,
  a.quote {
    color: $gold;
  }

  .footer-column-wrapper {
    padding: 20px;
  }

  a.logo {
    display: inline-block;
    margin: 0;
    width: 35px;
    height: 35px;
    transition: all 0.2s linear;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: top left;
    margin-right: 4px;

    &.facebook-anchor {
      background-image: url("../../images/Logos/facebook.png");
    }

    &.instagram-anchor {
      background-image: url("../../images/Logos/instagram.png");
    }
  }
}

.footer-card {
  background: transparent;
  border: none;

  img {
    padding-right: 10px;
    // height: 108px;
    width: 118px;
  }
}
