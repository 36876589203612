@import "../../style/variables.scss";

button.quote-button {
  background-image: linear-gradient(
    136deg,
    rgb(57, 82, 166) 0%,
    rgb(173, 38, 44) 100%
  );
  background-image: linear-gradient(136deg, $gold 0%, $gold-3 100%);
  color: $white;
  font-weight: 400;
  border: none;
}

button.quote-button:hover {
  transition: all 0.1s ease-in-out;
  background-image: linear-gradient(136deg, $gold-1 0%, $gold-3 100%);
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
}
