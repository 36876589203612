@import "../../style/variables.scss";

.image-card {
  border: none;
  border-radius: 0;
  //   box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  width: 100%;
  background: none;

  > .card-body {
    background: none;
    height: 300px;
    width: 100%;

    .image-card-details {
      z-index: 1;
      text-align: left;
      h3 {
        color: white;
        z-index: 1;
      }
      p {
        color: $white-2;
      }
    }

    .image-card-image {
      position: absolute;
      top: 0;
      left: 0;
      height: 300px;
      width: 100%;
      object-fit: cover;
    }

    .image-card-overlay {
      position: absolute;
      backdrop-filter: blur(3px);
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
      height: 100%;
      left: 0;
      top: 0;
      width: 100%;
    }
  }

  h3 {
    font-weight: bold;
  }

  p {
    font-size: 0.75rem;
  }
}

.card-content-container.closed {
  .image-card {
    transition: all 0.1s ease-in-out;
  }
  .image-card:hover {
    transform: scale(1.05);
    cursor: pointer;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.5);
  }
}

.card-content-container.open {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  padding: 40px 0;

  .image-card {
    border: none;
    border-radius: 0;
    pointer-events: auto;
    box-shadow: none;
    z-index: 1060;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;

    > .card-body {
      display: flex;
      height: 100%;
      width: 100%;

      .image-card-image {
        position: relative;
        height: auto;
        width: 100%;
        max-width: 700px;
        margin-top: 1.75rem;
        margin-left: auto;
        margin-right: auto;
        object-fit: contain;
      }
    }
  }
}
