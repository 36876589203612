// .card-content-container.open {
//   position: fixed;
//   top: 0;
//   left: 0;
//   z-index: 1050;
//   display: block;
//   width: 100%;
//   height: 100%;
//   overflow: hidden;
//   outline: 0;
//   padding: 40px 0;
// }

// .card-content {
//   pointer-events: auto;
//   z-index: 1060;
//   position: relative;
//   overflow: hidden;
//   width: 100%;
//   height: 100%;
//   margin: 0 auto;
//   display: flex;
// }

// .card-image {
//   position: relative;
//   height: auto;
//   width: 100%;
//   max-width: 700px;
//   margin-top: 1.75rem;
//   margin-left: auto;
//   margin-right: auto;
//   pointer-events: none;
//   object-fit: contain;
// }

.overlay-2 {
  z-index: 1040;
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  will-change: opacity;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

// // .card-content-container {
// //   top: 0;
// //   left: 0;
// //   right: 0;
// //   position: fixed;
// //   z-index: 1;
// //   overflow: hidden;
// //   padding: 40px 0;
// // }
