@import "../../style/variables.scss";

.service-row {
  margin-top: 3rem;
}

p.sub-header {
  font-size: 12px;
  line-height: 2.33em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-weight: 500;
  color: #565969;
  display: inline-block;
  margin: 0 0 5px;
}

.fullwidth-row {
  padding: 20px;
  width: 100vw;
  height: auto;
  margin-left: -50vw;
  left: 50%;
  position: relative;
  background: $blue;
  // background: url("../../images/LTM-Background.jpeg");
  background-position: 0% 55%;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;

  &.background-fixed {
    background-attachment: fixed;
  }
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25),
    -5px -5px 10px rgba(0, 0, 0, 0.25);

  .fullwidth-overlay {
    // backdrop-filter: blur(1px);
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0)
    );
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100vw;
  }

  .row {
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
  }
}

.quote-row {
  margin-top: 6rem;
  margin-bottom: 6rem;

  h3 {
    color: $red-1;
    font-weight: bold;
    font-family: Raleway;
  }

  .quote-wrapper {
    background: $white-2;
    text-align: center;
    vertical-align: middle;
    padding: 1rem;
    // border-top: 3px solid $red-1;
    background: rgba(255, 255, 255, 0.7) !important;
    backdrop-filter: blur(5px);
  }
}

.home-row {
  margin-top: 8rem;
}
